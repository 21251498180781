header {
  //background: $brand-color-5;
  background: $brand-color-4;
  //background: #FFF url(../images/i_layout/bg-header.png) no-repeat top fixed;
  //background-size: 100vw auto;
  //background: linear-gradient(to top,
  //        #FFF 0,
  //  //change_hsb($brand-color-1,$b:98%,$s:18%) 100%
  //        change_hsb($panel-default-heading-bg,$b:98%,$s:15%) 100%
  //  //change_hsb($brand-color-1,$b:100%,$s:10%) 140px,
  //  //#FFF 140px
  //);
  position: relative;
  z-index: 100;
  margin: 0 0 30px;
  //box-shadow: 0 3px 5px rgba(#000, .15);

  .brand {
    display: inline-block;
    padding: 8px 15px;
    background: $brand-color-4;
    img {
      max-height: 35px;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  header{
    >.container{
      padding: 0;
    }
  }

}
@media screen and (min-width: $screen-sm) {
  header {
    //background: linear-gradient(to bottom,
    //        $brand-color-5 0,
    //        $brand-color-5 100%
    //);
    //background: #e6e8dc;
    margin: 0 0 70px;
    box-shadow: 0 -25px 0 $body-bg inset;
    .container {
      //background-image: url('../images/i_layout/header.png');
      //background-size: contain;
      //background-position: top;
      //background-repeat: no-repeat;
    }
    //box-shadow: 0 -25px 0 #FFF inset;
    .brand {
      padding: 45px 0 50px;
      background: transparent;
      img {
        max-height: 70px;
      }
    }
    /*&::before{
      content:'';
      position: absolute;
      z-index: -1;
      background: red;
      top: 0;
      left: 0;
      right: 0;
      bottom: 25px;
      background: linear-gradient(to top,
              change_hsb($brand-color-1, $b: 95%, $s: 7%) 0%,
              change_hsb($brand-color-3, $b: 100%, $s: 0%) 100%
      );
    }*/
  }

}