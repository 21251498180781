
.row {
  &.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > [class^="col-"],
    > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
  &.row-pad-5 {
    margin-right: -5px;
    margin-left: -5px;

    > [class^="col-"],
    > [class*=" col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
    .thumbnail{
      margin-bottom: 10px;
    }
  }
  &.row-pad-10 {
    margin-right: -10px;
    margin-left: -10px;

    > [class^="col-"],
    > [class*=" col-"] {
      padding-right: 10px;
      padding-left: 10px;
    }
    .thumbnail{
      margin-bottom: 20px;
    }
  }
}
.row {
  &.clear-1-2-3-4 {
    > * {
      @media screen and (min-width: $screen-sm) {
        &:nth-child(2n+1) {
          clear: left;
        }
      }
      @media screen and (min-width: $screen-md) {
        &:nth-child(2n+1) {
          clear: none;
        }
        &:nth-child(3n+1) {
          clear: left;
        }
      }
      @media screen and (min-width: $screen-lg) {
        &:nth-child(3n+1) {
          clear: none;
        }
        &:nth-child(4n+1) {
          clear: left;
        }
      }
    }
  }
  &.clear-1-2-2-2 {
    > * {
      @media screen and (min-width: $screen-sm) {
        &:nth-child(2n+1) {
          clear: left;
        }
      }
    }
  }
  &.clear-1-2-3-3 {
    > * {
      @media screen and (min-width: $screen-sm) {
        &:nth-child(2n+1) {
          clear: left;
        }
      }
      @media screen and (min-width: $screen-md) {
        &:nth-child(2n+1) {
          clear: none;
        }
        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }
  &.clear-1-3-4-4 {
    > * {
      @media screen and (min-width: $screen-sm) {
        &:nth-child(3n+1) {
          clear: left;
        }
      }
      @media screen and (min-width: $screen-md) {
        &:nth-child(3n+1) {
          clear: none;
        }
        &:nth-child(4n+1) {
          clear: left;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .container{
    padding-left: 30px;
    padding-right: 30px;

  }
  /*.row{
    margin-left: -30px;
    margin-right: -30px;
  }*/
}
