body{
  //background: $body-bg url(../images/i_layout/bg-html.png) no-repeat top fixed;
  //background-size: 100vw auto;
}
* {
  outline: none !important;
}

img,iframe {
  max-width: 100%;
}
a#scrollToTop{
  display: block;
  visibility: hidden;
  position: fixed;
  right: 30px;
  bottom: 30px;
  background: #444;
  color: #FFF;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 36px;
  font-size: 14px;
  cursor: pointer;
  opacity: 0;
  transition: opacity ease .5s, visibility 0s .5s;
  &::before{
    content: '\f077';
    font-family: FontAwesome;
  }
  &:hover{
    background: $brand-color-1;
    text-decoration: none;
  }
  &.on{
    visibility: visible;
    transition: opacity ease .5s, visibility 0s 0s;
    opacity: 1;
  }
}
.no-scroll {
  overflow: hidden;
}
.btn{
  .fa{
    font-size: 14px;
  }
}

.gmap {
  //position: absolute;
  //width: 100%;
  //height: 100%;
  height: 500px;
  max-height: 80vh;
  img {
    max-width: none;
  }
  h2, h3, h4 {
    margin-top: 5px;
  }
  p {
    font-weight: normal;
  }
}


.map-overlay {
  position: absolute;
  bottom: 0;

  background: rgba(#3e3f39, .85);
  padding: 30px;
  h2 {
    font-weight: 100;
    text-transform: uppercase;
    margin: 0 0 10px;
  }

}

main {
  z-index: 1;
}

section.main {
  margin: 0;
  padding: 10px 15px 30px;

}

section.bg-1 {
  background: $brand-color-1;
}

section.bg-2 {
  background: $brand-color-2;
}

section.bg-2-paler {
  background: change_hsb($brand-color-2, $b: 95%, $s: 50%);
}

section.bg-3 {
  background: $brand-color-3;
  color:#FFF
}

section.bg-4 {
  color: #FFF;
  background: $brand-color-4;
  .big-title {
    h1, h2, h3, h4 {
      //color: $brand-color-2;
      strong, b {
        color: #FFF;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    padding-left: 15px;
    margin: 0;
    li {
      position: relative;
      margin-bottom: 8px;
      &::before {
        content: '\25AA';
        position: absolute;
        color: $brand-color-2;
        left: -15px;
      }
    }
  }
}

.padTop {
  padding-top: 180px;
}

.padV {
  padding-top: 80px;
  padding-bottom: 80px;
}
.padH {
  padding-left:40px;
  padding-right: 40px;
}

section.paper {
  background: #fff;
  color: #444;
  &.work-item {
    padding: 100px 0;
    min-height: 100%;
  }
}

ul.check {
  list-style: none;
  padding: 0;
  > li {
    &::before {
      content: '\f00c';
      font-family: FontAwesome;
      margin-right: 5px;
      color: #9bc66d;
    }
  }
}

section.brands {
  background: #FFF;
  margin: 0 -15px;
  padding: 10px 30px 15px;
  img + img {
    margin-left: 10px;
  }
}

.twitter-timeline.twitter-timeline-rendered {
  height: 450px !important;
}

section.dark {
  background: #363636;
  padding: 30px 10px;
  color: #FFF;
  .embed-responsive {
    margin: 0 0 30px;
  }
  h2, h3, h4, h5 {
    color: #FFF;
    text-transform: uppercase;
    margin-top: 0;
  }
}

section.grey {
  background: #f3f3f3;
}

.overall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  &:hover, &:focus {
    text-decoration: none;
  }
}

.section-title {
  h1, h2 {
    color: $brand-color-1;
    text-transform: uppercase;
    margin: 0;
    font-size: 25px;
    font-weight: bold;
    padding: 10px 0;
  }
  &.inverse {
    background: $brand-color-1;
    h1, h2 {
      color: #FFF;
    }
  }
}

label.control-label.required:after {
  content: ' *';
  color: #F04124;
  font-weight: normal;
  white-space: nowrap;
  /*
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 5px;
  */
}

.has-error label.control-label.required:after {
  content: "\e101";
  font-family: 'Glyphicons Halflings';
  font-size: 14px;
  top: 2px;
  margin-left: 3px;
}

.form-captcha {
  img {
    max-width: none;
  }
  .input-group-addon {
    padding: 0;
    border: none;
  }
}



.accordion {
  .panel-body {
    border-top: 1px solid $panel-default-border;
  }
  .drawer {
    .toggler {
      cursor: default;
      position: relative;
      padding-right: 28px;
      &::after {
        content: '\f0d7';
        font-family: FontAwesome;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover {
        background: adjust_hsb($brand-color-5, $b: -1%, $s: 3%);
        text-shadow: 0 1px 0 #FFF;
      }
    }
    &.on {
      .toggler {
        &::after {
          content: '\f0d8';
        }
      }
    }
  }
}

@media (min-width: $screen-sm) {
  section.main {
    padding: 20px 30px 30px;

  }
  .map-overlay {
    width: 33%;
    top: 50px;
    right: 50px;
    bottom: auto;
  }
}
