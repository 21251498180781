.contact-form {
  //background: rgba(#000, .03);
  background: $brand-color-5;
  .intro {
    margin: 100px 0 60px;
    text-align: center;
  }
  form {
    margin: 40px 0;
  }
}

.contact-form, .contacts-ul {
  h2, h3, h4, h5, h6 {
    color: #39ae84;
  }
  h2 {
    color: #39ae84;
    text-transform: uppercase;
    letter-spacing: .12em;
  }
  > div {
    h5 {
      &::after {
        display: block;
        margin: 10px 0 0;
        content: '     ';
        font: {
          size: 9px;
          family: FontAwesome;
          weight: normal;
        }
      }
    }
    &:nth-child(n+7) {
      h5 {
        &::after {
          display: none;
        }
      }
    }
  }
  > div {
    @for $i from 1 through length($act-pids) {
      $col-i: nth($act-colors, $i);
      &:nth-child(#{$i}) {
        h5 {
          &::after {
            color: $col-i;
          }
        }
      }
    }
  }
}

.contacts-ul {
  margin-top: 16px;
}

.contacts-li {
  margin-bottom: 40px;
}

@media (min-width: $screen-sm) {
  .contacts-ul {
    margin-left: -30px;
    margin-right: -30px;
    > div {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .contacts-li {
    font-size: 14px;
  }
}