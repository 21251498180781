.btn {
  font-family: $headings-font-family;
}

.btn-default {
  box-shadow: 0 3px 2px -2px rgba(#000, .1);
  &:hover {
    background: #FFF;
    border-color: $brand-color-1;
    &:focus, &:active {
      background: #FFF;
      border-color: $brand-color-1;
    }
  }
  &:focus, &:active {
    background: #FFF;
    border-color: $brand-color-1;
  }
}

.btn-round {
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  &.btn-lg {
    border-radius: 24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  &.btn-sm {
    border-radius: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.btn-circle {
  width: 36px;
  height: 36px;
  text-align: center;
  padding: 6px 0;
  font-size: 14px;
  line-height: 1.8;
  border-radius: 18px;
  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
  }
  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
  }
}

.btn-social {
  border-radius: 4px;
  margin-bottom: 8px;

}

.btn-social.btn-lg, .btn-group-lg > .btn-social.btn, .btn-group-lg > .btn.btn-social-icon, .btn-lg.btn-social-icon {
  > :first-child {
    line-height: 2;
    width: 54px;
    font-size: 1.2em;
  }
}

.btn-o {
  @extend .btn;
  font-weight: 600;
  &.btn-primary {
    border: 2px solid $brand-color-1;
    color: $brand-color-1;
    background: transparent;
    transition: color .15s ease, background-color .15s ease;
    &:hover, &:focus {
      background: $brand-color-1;
      color: #FFF;
      border-color: $brand-color-1;
    }
  }
  &.btn-secondary {
    border: 2px solid $brand-color-2;
    color: $brand-color-2;
    background: transparent;
    transition: color .15s ease, background-color .15s ease;
    &:hover, &:focus {
      background: $brand-color-2;
      color: #FFF;
      border-color: $brand-color-2;
    }
  }
}

.info-toggler {
  display: inline-block;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  vertical-align: middle;
  &::before {
    content: '\f129';
    display: inline-block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    border-radius: 50%;
    text-align: center;
    background: #FFF;
    color: $brand-info;
    box-shadow: 0 0 0 2px $brand-info inset;
    font: {
      family: FontAwesome;
      size: 14px;
      weight: normal;
    }
  }
  &:hover {
    &::before {
      background: $brand-info;
      color: #FFF;
    }
  }
}