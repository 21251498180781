[data-gfx="slick"] {
  padding: 0;
  img {
    width: 100%;
  }
}

.slick-slider {
  &.slick-dotted {
    margin-bottom: 0;
  }
  .slick-slide {
    position: relative;
    .caption {
      position: absolute;
      top: 50%;
      left: 6%;
      right: 6%;
      transform: translateY(-35%);
      transition: all .5s ease .5s;
      opacity: 0;
      padding: 0 15px;
      > * {
        //max-width: 75%;

      }
    }
    &.slick-current {
      .caption {
        transform: translateY(-50%);
        opacity: 1;
      }
    }
  }
}

.slick-arrow {
  text-shadow: 0 0 10px rgba(#000, .3), 0 0 60px rgba(#000, .8);
}

.slick-dots {
  bottom: 8px;
  li {
    margin: 0;
    button {
      &:before {
        color: #FFF;
        opacity: .5;
        font-size: 6px;
      }
    }
    &.slick-active {
      button {
        &:before {
          color: #FFF;
          opacity: 1;
          text-shadow: 0 0 5px rgba(#000, .5);
        }
      }
    }

  }
}

.slick-arrow {
  width: 40px;
  height: 60px;
  //background: $brand-color-1;
  color: #FFF;
  //background: #FFF;
  //border: 1px solid $panel-default-border;
  //color: $panel-default-border;
  &:before {
    font: {
      size: 36px;
      family: FontAwesome;
    }
    color: inherit;
    text-shadow: none;
  }
  &:hover, &:focus {
    //background: $brand-color-1;
    //border-color: $brand-color-1;
    color: #FFF;
  }
}

.slick-prev {
  left: 0;
  //border-radius: 0 3px 3px 0;

  &:before {
    content: '\f104';
  }
}

.slick-next {
  right: 0;
  //border-radius: 3px 0 0 3px;
  &:before {
    content: '\f105';
  }
}

.slider-nav {
  padding: 10px 30px 10px;
  //display: none;
  .slick-slide {
    position: relative;
    cursor: pointer;
    display: block;
    padding: 2px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      transition: all .5s ease;
    }
    &:hover {
      &::after {
        box-shadow: inset 0 0 0 2px rgba($brand-color-1, .4);
      }
    }
    &.slick-current {
      &::after {
        box-shadow: inset 0 0 0 2px rgba($link-color, 1);
      }
    }
  }
  .slick-arrow {
    width: 30px;
    color: $brand-color-1;
  }
  .slick-prev {
    //left:-30px;
  }
  .slick-next {
    //right:-30px;
  }

}

.slider-products-header {
  margin-bottom: 40px;
  .slick-slide {
    .bg-img {
      padding-top: 50%;
    }

    .zoom {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      margin-right: 20px;
      margin-bottom: 20px;
      border-radius: 3px;
      text-decoration: none !important;
      border:1px solid rgba(#FFF,.4);
      transition:all .2s ease;
      opacity: 0;
      &::after {
        content: '\f00e';
        color: #FFF;
        font: {
          family: FontAwesome;
          size: 28px;
        }
      }
      &:hover{
        border-color: #FFF;
      }
    }
    &:hover{
      .zoom{
        opacity:1;
      }
    }
  }
  h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    //letter-spacing: .1em;
  }
  h2, h3, h4, h5, h6, p {
    color: #FFF;
    margin-bottom: 5px;
    margin-top: 0;
  }

}

@media screen and (max-width: $screen-xs-max) {
  .slider-products-header {
    .slick-slide {
      .caption * {
        text-align: center !important;
      }
    }
  }
}

@media screen and (min-width: $screen-md) {
  .slider-products-header {
    .slick-slide {
      .bg-img {
        padding-top: 33.33%;
      }
    }
  }
}