.cards {
  margin-top: 40px;
  margin-bottom: 40px;
  @for $i from 1 through length($act-pids) {
    $col-i: nth($act-colors, $i);
    $pid-i: nth($act-pids, $i);

    &-#{$pid-i} {
      .bg-grad {
        &::before {
          background: linear-gradient(130deg,
                  change_hsb($col-i, $s: .6, $b: .8, $a: 1) 10%,
                  change_hsb(adjust_hue($col-i, -45deg), $s: .9, $b: .4, $a: 1) 90%
          );
        }
      }
      .marketing_object {
        .card {
          &::before {
            border: 2px solid rgba($col-i, 1);
          }
        }
        h4 {
          background: $col-i;
        }
        .btn-o.btn-primary {
          color: $col-i;
          &:hover {
            background: $col-i;
          }
        }
      }
    }
  }
  .marketing_object {
    //float: right;
    .bg-img {
      img {
        min-width: 100%;
        min-height: 100%;
      }
    }
    .card {
      //padding-top: 0;
      > p {
        margin: 0;
      }
      &::before {
        content: '';
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
        bottom: 20px;
        left: 20px;
        z-index: 10;
        transform: rotate(5deg);
        pointer-events: none;
      }
    }
    .btn-o.btn-primary {
      font: {
        family: $font-family-monospace;
        size: 10px;
      }
      letter-spacing: .16em;
      text-transform: uppercase;
      border-color: #FFF;
      background: #FFF;
      &:hover {
        color: #FFF;
        border-color: #FFF;
      }
    }
  }
}

.card {
  display: block;
  position: relative;
  margin-bottom: 30px;
  padding-top: 133.33333%;
  perspective: 1000px;
  overflow: hidden;
  //background: mix(#FFF,$brand-color-5,50%);
  //border:1px solid $brand-color-5;
  //box-shadow: 0 3px 2px -2px rgba(#000,.1);
  .overall {
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    width: auto;
    height: auto;
  }
  .bg-img {
    transition: transform .3s ease;

  }
  .bg-grad {
    &::before, &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: .6;
      transition: opacity .2s ease;
      //box-shadow: 0 0 60px rgba(#000,.5) inset;
    }
    &::after {
      background: $brand-color-3;
      opacity: 0;
    }
  }
  .body {
    padding: 20px 30px;
    text-align: center;
    transition: all .2s ease;
    //background: linear-gradient(120deg, rgba($brand-color-1, 0.6) 10%, rgba($brand-color-3, 0.6) 90%);
    //border-top:1px solid $brand-color-5;
    //display: none;
    * {
      color: #FFF;
      z-index: 1;
    }
    h4 {
      text-transform: uppercase;
      display: inline-block;
      border: 2px solid #FFF;
      letter-spacing: .15em;
      font: {
        family: $font-family-monospace;
        weight: bold;
        size: 13px;
      }
      padding: 10px 20px;
    }
    .desc {
      height: 55px;
      transition: all .2s ease;
    }
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      width: 60%;
      height: 4px;
      transform: translate(-50%, -50%) rotate(-45deg);
      top: 50%;
      left: 50%;
      //margin-left: -30%;

      transition: all .2s ease;
    }
    &::after {
      //transition: all .25s ease;
    }
  }
  &:hover {
    .bg-img {
      transform: translate3d(0, 0, 100px);
    }
    .bg-grad {
      &::before {
        opacity: .8;
      }
      &::after {
        //opacity: .2;
      }
    }
    .body {
      //.desc{
      //  height: 0;
      //  opacity:0;
      //}
      &::before {
        //width: 60%;
        height: 50px;
        transform: translate(-50%, -50%) rotate(0deg);
      }
      &::after {
        //width: 60%;
        height: 50px;
        transform: translate(-50%, -50%) rotate(90deg);
        transition: all .25s ease;
      }
    }
  }

}

.card-2 {
  background: #FFF;
  //border: 1px solid rgba(#000, .08);
  box-shadow: 0 4px 2px -2px rgba(#000, .06);
  color: #738396;
  font-size: 14px;
  //margin-bottom: 30px;

  a.pic {
    display: block;
    padding-top: 66%;
    text-decoration: none !important;
    font-size: 0;
  }
  h6 {
    color: #738396;
    font: {
      size: 10px;
      weight: 700;
    }
  }
  h4 {
    letter-spacing: -.04em;
    line-height: 1.3;
    margin-bottom: 18px;
    font: {
      size: 21px;
      weight: 800;
    }
    a {
      transition: all .2s ease;
        border-bottom: 1px solid transparent;
      &:hover {
        color:$link-color;
        text-decoration: none;
        border-bottom: 1px solid $link-color;
      }
    }
  }
  .body {
    position: relative;
    padding: 50px 12% 65px;
  }
  .abstract {
    font-size: 13px;
    line-height: 2.2;
  }
  .footer {
    text-align: right;
    position: absolute;
    bottom: 35px;
    left: 12%;
    right: 12%;
    .date {
      float: left;
      font-size: 11px;
      font-weight: 900;
      letter-spacing: .3em;
    }
    .more {
      //position: absolute;
      //right: -10px;
      //top: -10px;
      font: {
        size: 11px;
        family: $headings-font-family;
        weight: 700;
      }
      text-transform: uppercase;
      letter-spacing: .15em;
      display: inline-block;
      border-bottom: 1px solid transparent;
      //padding: 1px 0;
      //color: $brand-color-2;
      transition: all .2s ease;
      &::after {
        content: '\f178';
        font: {
          family: FontAwesome;
          size: 14px;
          weight: normal;
        }
        margin-left: 6px;
      }
      &:hover {
        color: $link-color;
        text-decoration: none;
        border-bottom: 1px solid $link-color;
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .cards {
    .marketing_object {
      float: right;

    }
  }
}