.about-numbers {
  padding: 60px 0 40px;
  background: #e9ebe0;
  text-align: center;
  .row {
    margin-top: 60px;
    > div {
      margin-bottom: 60px;
    }
  }
  h2 {
    color: #39ae84;
    font: {
      //size:30px;
      weight: 300;
    }
    strong {
      font-weight: normal;
    }
    &.title {
      text-transform: uppercase;
      letter-spacing: .12em;
    }
  }
}

.text-pic, .product-category {
  .pic-container {
    margin-bottom: 40px;
  }
  .picture {
    position: relative;
    width: 100%;
    background-position: center;
    background-size: cover;
    padding-top: 50%;
  }
  .body {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: $screen-sm) {
  .text-pic, .product-category {
    .pic-container {
      float: left;
      width: 40%;
      margin: 0 7% 5% 0;
    }

    .picture {
      padding-top: 130%;
    }
    .body {
      overflow: hidden;
      padding-top: 80px;
      //text-align: justify;
      margin-left: 20%;
      //margin-right: 10%;
      //margin-bottom: 80px;
      ul {
        overflow: hidden;
        li {
          margin-bottom: 8px;
        }
      }
    }
  }
  .product-category {
    .pic-container {
      float: right;
      width: 35%;
      margin: 0 0 5% 7%;
    }
    .body {
      padding-top: 0;
      margin-left: 0;
      text-align: left;

    }
  }
}

@media screen and (min-width: $screen-md) {
  .product-category {
    .body {
      margin-left: 30px;
    }
  }
}