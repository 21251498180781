.half-wh {
  display: block;
  width: 100%;
  height: 50%;
  position: relative;
  overflow: hidden;
  background: #000;
  z-index: 1;
}

.full-h{
  height: 100%;
  min-height: 100%;
}

.half-h {
  //display: block;
  min-height: 50%;
  position: relative;
  //overflow: hidden;
}
/*.flexbox{
  display: flex;
  background: $body-bg;
  >*{
    flex: 1;
    position: relative;
    box-sizing: border-box;
  }
}*/

.half-w{
  width: 100%;
  position: relative;
}
.text-pad{
  padding: 10% 15%;
}
.half-w.full-h{
  display: block;
  float: left;
}
.bg-img{
  background-position: center;
  background-size: cover;
}
.centerHV{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
}

.third-wh {
  display: block;
  width: 33.333333%;
  height: 33.333333%;
  float: left;
  position: relative;
  overflow: hidden;
  &:nth-child(3n) {
    transform: translate(-1px, 0);
  }
}

.third-h {
  display: block;
  height: 33.333333%;
  position: relative;
  overflow: hidden;
}


.middle-v {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bg-grid {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(../images/i_layout/grid.png);
  }
}

@media screen and (min-width: $screen-sm) {
  .half-w{
    width: 50%;
  }
  .half-wh {
    width: 50%;
    float: left;
  }
}