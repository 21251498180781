
.basket-steps {
  margin: 0 0 70px;
  font: {
    family: $headings-font-family;
    size: 14px;
  }
  //font-family: "Raleway", sans-serif;
  ul {
    display: table;
    position: relative;
    list-style: none;
    width: 100%;
    padding: 0;
    text-align: center;
    margin: 0 0 15px;
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 22px;
      left: 10%;
      right: 10%;
      border-bottom: 2px solid $brand-color-1;
    }
    > li {
      counter-increment: bstep;
      border-radius: 3px;
      //font-family: "Roboto Condensed",sans-serif;
      //color: change_hsb($brand-color-1, $b: 70%, $s: 20%);
      color: $link-color;
      display: table-cell;
      width: 20%;
      font: {
        weight: 500;
      }
      //border-bottom: 2px solid change_hsb($brand-color-1, $b: 90%, $s: 8%);
      border-left: none;
      padding: 5px 0;

      &::before {
        content: counter(bstep);
        //float: left;
        font: {
          size: 21px;
          weight: normal;
          //family: $headings-font-family;
        }
        display: inline-block;
        //border: 2px solid change_hsb($brand-color-1, $b: 94%, $s: 6%);
        border: 2px solid $brand-color-1;
        //background: change_hsb($brand-color-1, $b: 98%, $s: 1%);
        color: #FFF;
        background: $brand-color-1;
        //background: $body-bg;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        line-height: 31px;
        //color: #999;
      }

      &.active {
        //background: $link-color;
        color: $link-color;
        //border-color: $brand-color-2;
        > span {
          display: block;
        }
        &::before {
          //font-weight: bold;
          background: $link-color;
          border-color: $link-color;
          font-weight: bold;
          color: #FFF;
          transform: scale(1.3, 1.3);
        }
        & + li, & + li + li, & + li + li + li, & + li + li + li + li {
          &::before {
            background: #FFF;
            color: $link-color;
          }
        }

      }
      > span {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        text-align: center;
        padding: 10px 0 0;
        line-height: 1.3;
      }
    }

  }
}

table.basket, table.price {
  //color: change_hsb($brand-color-3, $b: 55%, $s: 45%);
  //border-color: $brand-color-3;
  td, th {
    vertical-align: middle !important;
    //border-color: change_hsb($brand-color-3, $b: 90%, $s: 8%) !important;
  }
  th {
    color: change_hsb($brand-color-3, $b: 55%, $s: 45%);
  }

  .thumb {
    max-width: 64px;
  }
  input.qty {
    float: left;
    width: 70px;
    border-radius: 3px 0 0 3px;
  }
}

.basket-box {
  font-family: 'Roboto Condensed';
  .amount {
    float: right;
  }
}

.transporters-list {
  text-align: center;
  margin: 20px 0;
  > div {
    display: flex;
    align-content: center;
    margin-bottom: 20px;
  }
}

.transporters-list-title {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.transporter-link {
  display: flex;
  flex-direction: row;
  transition: all .2s;
  width: 100%;
  background: $body-bg;
  padding: 30px 20px;
  //align-items: center;
  //text-align: center;
  //height: 100%;
  //max-width: 120px;
  border: 2px solid transparent;
  > * {
    align-self: center !important;
    margin: auto;
  }
  img {
    opacity: .6;
    width: 150px;
  }
  &:hover, &:focus {
    //border-color: $brand-color-1;
    text-decoration: none;
    background: #FFF;
    img {
      opacity: 1;
    }
  }
  &.active {
    border-color: $link-color;
    background: #FFF;
    img {
      opacity: 1;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: $screen-sm) {
  .basket-steps {
    margin: 0 0 45px;
    font-size: 13px;
    ul {
      &::before {
        top: 32px;
      }
      > li {
        position: relative;
        padding: 15px 10px;
        /*&.active {
          background: $link-color;
          color: #FFF;
          box-shadow: 0 0 20px 10px $body-bg;

          &::before{
            transform:none;
            background: #FFF;
            border-color: #FFF;
            color: $link-color;
          }
        }*/
        > span {
          display: block;
          position: relative;
        }
      }
    }
  }
}

.payment-methods {
  margin: 30px 0;
  > * {
    margin-bottom: 15px;
  }
  .payment-btn {
    display: block;
    width: 100%;
    padding: 15px 20px;
    background: $link-color;
    color: #FFF;
    border: none;
    border-radius: 5px;
    transition: background-color .2s;
    font: {
      weight: 500;
    }
    .fa {
      margin-right: 10px;
      font-size: 14px;
    }
    &:hover {
      background-color: darken($link-color, 5%);
    }
  }
  @media (min-width: $screen-sm) {
    display: flex;
    margin: 30px -5px;
    > * {
      padding-left: 5px;
      padding-right: 5px;
    }
    .payment-btn {
      text-align: left;
      height: 100%;
      padding: 20px;
      min-width: 200px;
      span {
        display: block;
      }
    }
  }
}
