nav.navbar {
  //position: fixed;
  //font-family: 'Oswald', 'Raleway', sans-serif;
  font: {
    size: 13px;
    weight: 700;
  }
  text-transform: uppercase;
  margin-bottom: 0;
  //box-shadow: 0 2px 2px rgba(change_hsb($brand-color-1, $s: 20%), .2);
  //font-weight: 600;
  //text-transform: uppercase;

  top: 0;
  left: 0;
  right: 0;
  border: none;
  .navbar-header {
    position: relative;
    //z-index: 1;
  }
  a.logo {
    display: inline-block;
    margin: 8px 15px;
    display: inline-block;
    line-height: 0;
    color: $brand-color-1;
    //max-height: 32px;
    //width: auto;
    svg {
      width: 70px;
      height: 30px;
    }

  }
  .fa, .el {
    &.fa-caret-down {
      font-size: 12px;
    }
    &:first-child {
      font-size: 14px;
      margin-right: 5px;
    }
  }
  .dropdown-icon {
    line-height: inherit;
    //margin: 0 0 0 7px;
    &::before {
      content: '\f107';
      display: inline-block;
      font: {
        family: FontAwesome;
        size: 12px;
        weight: normal !important;
        style: normal !important;
      }
    }
  }
  .dropdown-menu {
    border-radius: 3px;
  }
  .navbar {
    margin-bottom: 0;
    border: none;
  }
  &.main-nav {
    position: relative;
    /*&::before {
      content: '';
      background: #FFF;
      transition: $nav-transition;
      z-index: 0;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }*/
    .navbar-nav {
      > li > a {
        //text-transform: uppercase;
      }
    }
  }
  .flag {
    transform: translateY(-1px);
    margin-right: 8px;
    box-shadow: 0 1px 1px rgba(#000, .3);
  }
  .navbar-toggle {
    background: transparent !important;
    border-color: rgba(#FFF, 0);
    transition: border-color .3s ease;
    &:hover, &:active {
      border-color: rgba(#FFF, .5);

    }
  }
  /*
    &.open {
      visibility: visible;
      transition: none;
    }*/

  li.dropdown {
    > a.dropdown-toggle {
      //display: block !important;
      position: absolute;
      top: 0;
      right: 0;
      padding-left: 15px;
      padding-right: 15px;
      > .fa-caret-down {
        display: inline;
        margin: 0;
      }
      > .dropdown-icon {
        display: inline-block;
        &::before {
          margin: 0;
        }
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        height: 20px;
        margin-top: -10px;
        //bottom: 15px;
        width: 0;
        border-left: 1px solid rgba(#000, 0.15);
        border-right: 1px solid rgba(#FFF, 0.15);
      }
    }
  }
  .dropdown-menu {
    font-size: inherit;
    text-transform: none;
    > li {
      > a {
        //font-weight: 600;
      }
    }
  }
}

html {
  &.touch {
    nav.navbar {
      .dropdown-icon {
        display: none;
      }
      .dropdown-toggle {
        display: block;
        .dropdown-icon {
          display: inline-block;
        }
      }
      li.dropdown {
        > a:not(.dropdown-toggle) {
          padding-right: 50px;
        }
        &.open {
          > a {
            background-color: transparent;
          }
        }
      }
    }
  }
  &.no-touch {
    nav.navbar {
      .dropdown-toggle {
        display: none;
      }
    }
  }
}

@media (max-width: $grid-float-breakpoint-max) {
  .navbar-nav {
    //margin-top: 1px;
    //margin-bottom: 0;
  }

  .lang-menu {
    display: block;
    position: relative;
    background: none;
    float: none;
    padding: 0;
    border: none;
    //border-top: 1px solid #eee;
    > a {
      display: none !important;
    }
    .dropdown-menu {
      position: relative !important;
      display: flex !important;
      border-radius: 0 !important;
      box-shadow: none;
      padding: 0;
      width: 100%;
      > li {
        flex: 1;
        > a {
          padding: 10px 0;
          font: {
            weight: bold;
          }
          text: {
            transform: uppercase;
            align: center;
          }
        }
        &.active {
          > a {
            &::before {
              display: none;
            }
          }
        }
      }
    }

  }

  //html.touch, html.no-touch {
  nav.navbar {
    .dropdown-icon {
      display: none;
    }
    .dropdown-toggle {
      display: block;
      .dropdown-icon {
        display: inline-block;
      }
    }
    li {
      &.dropdown {
        > a:not(.dropdown-toggle) {
          padding-right: 50px;
        }
      }
      &.active > a:not(.dropdown-toggle) {
        position: relative;
        //box-shadow: 3px 0 0 $brand-color-2 inset;
        &::before {
          content: '';
          position: absolute;
          top: 12px;
          bottom: 12px;
          left: 0;
          width: 3px;
          background: $brand-color-2;
        }
      }
    }
  }
  //}

  .nav-secondary {
    //margin-top: -4.5px;
    //position: absolute;
    //top:-70px;
    //right: 15px;
  }
  .dropdown-menu {
    //border-top: 1px solid $brand-color-2 !important;
    border: 1px solid rgba(#FFF, .15) !important;
    border-width: 1px 0 !important;
    //background-color: #f5f5f5 !important;
  }
}

//@media screen and (min-width: $screen-sm) {
@media screen and (min-width: $grid-float-breakpoint) {
  .dropdown-icon::before {
    margin: 0 0 0 7px;
  }
  .navbar-wrapper {
    position: relative;
  }
  nav.navbar {
    border-radius: 3px;
  }
  .navbar-collapse {
    //position: relative;
    .dropdown-menu {
      > li > a {
        font: {
          weight: 600;
          size: 12px;
        }
        padding: 8px 20px;

      }

      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 15px;
        margin-top: -8px;
        //margin-left: -8px;
        width: 0;
        height: 0;
        //z-index: 1501;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent $dropdown-bg transparent;
      }
      &::before {
        border-color: transparent transparent rgba(#000, 0.15) transparent;
      }
      &::after {
        transform: translateY(1px);
        //display: none;
      }
      .dropdown {
        .dropdown-icon {
          //position: absolute;
          //top: 8px;
          //right: 8px;
          &::before {
            content: '\f105';
          }
        }
        > a:first-child {
          padding-right: 24px;
          .dropdown-icon {
            position: absolute;
            top: 8px;
            right: 8px;
            &::before {
              //content: '\f105';
              //float: right;
              //margin-right: -10px;
              //margin-top: 2px;

            }
          }
          /*&::after {
            content: '\f105';
            display: block;
            position: absolute;
            top: 6px;
            right: 8px;
            font: {
              family: FontAwesome;
              size: 12px;
              weight: normal !important;
              style: normal !important;
            }

            line-height: inherit;
            //transform: translate3d(12px, 3px, 0);
          }*/
        }
        .dropdown-menu {
          top: -8px;
          left: 100%;
          margin-left: -1px;
          &::before, &::after {
            display: none;
          }
        }
      }
    }
    .dropdown.open {
      .dropdown.open {
        background-color: #FFF;
        > a {
          background-color: $dropdown-link-hover-bg;
          color: $navbar-inverse-link-hover-color;
        }
      }
      &::before, &::after {
        //content: '';
        //display: block;
        //position: absolute;
        //pointer-events: none;
        //bottom: 0;
        //left: 50%;
        //margin-left: -8px;
        //width: 0;
        //height: 0;
        //z-index: 1501;
        //border-style: solid;
        //border-width: 0 8px 8px 8px;
        //border-color: transparent transparent $dropdown-bg transparent;
      }
      &::before {
        //border-color: transparent transparent rgba(#000, 0.15) transparent;
      }
      &::after {
        //transform: translateY(1px);
        //display: none;
      }
    }
  }
  /*nav.main-nav {
    //transition: all .3s ease;
    margin-top: 20px;
    border-radius: 5px;
    //transition: $nav-transition;
    a.logo {
      margin: 20px 0;
      margin-right: 40px;
      line-height: 0;
      //transition: all .3s ease;
      //transition: $nav-transition;
      svg {
        //display: inline-block;
        //max-height: none;
        width: 128px;
        height: 56px;
        //transition: all .3s ease;
        //transition: $nav-transition;
      }
    }
    .navbar-wrapper {
      margin-top: 20px;
      //transition: all .3s ease;
      //transition: $nav-transition;
    }

  }*/
  .navbar-right {
    .dropdown.open {
      .dropdown-menu {
        &::before, &::after {
          left: auto;
          right: 15px;
        }
      }
    }
  }
  .nav-primary {
    > li {
      /*> a:not(.dropdown-toggle) {
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 20px;
          height: 3px;
          margin-left: -10px;
          background: $brand-color-2;
          transform: translateY(5px);
          pointer-events: none;
          opacity: 0;
          transition: opacity .2s, transform .2s;
        }
      }
      &.active > a, &:not(.dropdown) > a:hover {
        &::after {
          transform: translateY(0);
          opacity: 1;
        }
      }
      &.open {
        > a::after {
          display: none;
        }
      }*/
    }
    .dropdown-menu {
      li.active {
        > a {
          background: $brand-color-2 !important;
        }
      }
    }
  }
  .nav-secondary {
    position: absolute;
    top: -110px;
    right: 15px;
    //background: #96a161;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #2b5a46 inset;
    > li {
      > a {
        color: #FFF !important;
        &:hover {
          //background: darken(#96a161, 6%) !important;
          background: #2b5a46 !important;
        }
      }
      &.open {
        > a {
          //background: darken(#96a161, 6%) !important;
          background: #2b5a46 !important;
        }
      }
      &:first-child {
        > a {
          border-radius: 3px 0 0 3px;
        }
      }
      &:last-child {
        > a {
          border-radius: 0 3px 3px 0;
        }
      }
    }
    a.contactzzz {
      line-height: 1.8;
      font-weight: 600;
      color: $brand-color-2 !important;
      box-shadow: 0 0 0 2px $brand-color-2 inset;
      border-radius: 25px;
      height: 50px;
      padding: 11.5px 30px;
      transition: background-color .15s, color .15s;
      &:hover {
        color: #FFF !important;
        background: $brand-color-2 !important;
      }
    }
    /*a.user {
      text-align: center;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      border: 2px solid transparent;
      background: rgba($brand-color-1, .1) !important;
      margin-right: 20px;
      transition: border-color .15s;
      .el {
        transform: translateY(-2px);
      }
      &:hover {
        border-color: rgba($brand-color-1, .6);
      }
    }*/
  }
}
