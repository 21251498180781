$icon-font-path: "fonts/bootstrap/";

//@import "../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap";
//@import url('http://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900,900italic,700italic,500italic,400italic,300italic,100italic&subset=latin,cyrillic');
@import url('http://fonts.googleapis.com/css?family=Roboto:400,500,700');
//@import 'https://fonts.googleapis.com/css?family=Catamaran:100,200,300,400,500,600,700,800,900';
//@import 'https://fonts.googleapis.com/css?family=Martel+Sans:200,300,400,600,700,800,900&subset=latin-ext';
//@import 'https://fonts.googleapis.com/css?family=Kanit:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i';
//@import 'https://fonts.googleapis.com/css?family=Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900';
//@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i';
//@import 'https://fonts.googleapis.com/css?family=Dosis:200,300,400,500,600,700,800';
//@import 'https://fonts.googleapis.com/css?family=Josefin+Sans:100,100i,300,300i,400,400i,600,600i,700,700i';
//@import 'https://fonts.googleapis.com/css?family=Quicksand:300,400,700';
//@import 'https://fonts.googleapis.com/css?family=Concert+One';
//@import 'https://fonts.googleapis.com/css?family=Cairo:200,300,400,600,700,900';
//@import 'https://fonts.googleapis.com/css?family=Comfortaa:300,400,700';
//@import 'https://fonts.googleapis.com/css?family=Khand:300,400,500,600,700';
//@import 'https://fonts.googleapis.com/css?family=Marvel:400,400i,700,700i';



//@import url('http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,300,400,600,700,800');
@import url(https://fonts.googleapis.com/css?family=Raleway:100,300,400,500,600,700,800,900,700italic,400italic);
@import url('http://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,500,700');
//@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,400italic,700,700italic,900italic,900);
//@import url(https://fonts.googleapis.com/css?family=Lora:400,400italic,700,700italic);
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Montserrat:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
//@import url(http://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700);

//@import "../../bower_components/lightGallery/src/css/lightgallery.css";
// SLICK SLIDER

@import "slick/main";




@import "../styles/fonts/fontawesome/css/font-awesome.css";
@import "../styles/fonts/elusive/css/elusive-icons.css";
//@import "../styles/fonts/GAV/stylesheet.css";

//@import "partials/colors";
//@import "bootstrap/yeti/variables";
@import "mixins/hsv";
@import "partials/vars";
@import "bootstrap/bootstrap";
//@import "bootstrap/yeti/bootswatch";
//BOOTSTRAP SOCIAL
@import "../../bower_components/bootstrap-social/bootstrap-social.scss";
//----------------

@import "partials/easing";
@import "partials/jquery.lightbox";
@import "partials/base";
//@import "partials/carousel";
@import "partials/nav";
@import "partials/products-menu";
@import "partials/header";
@import "partials/header-img";
@import "partials/type";
@import "partials/icons";
@import "partials/pagination";
@import "partials/form";
@import "partials/articles";
@import "partials/account";
@import "partials/basket";
@import "partials/sort-nav";
@import "partials/gallery";
@import "partials/blocks";
@import "partials/tables";
@import "partials/panels";
@import "partials/buttons";
@import "pnotify/main";

@import "partials/search";
@import "partials/home";
@import "partials/about";
@import "partials/tags";
//@import "partials/people";
//@import "partials/schedule";
@import "partials/register";
@import "partials/grid";
@import "partials/grid-flex";
@import "partials/modals";
//@import "partials/practical-info";
@import "partials/breadcrumb";
@import "partials/agenda";
@import "partials/news";
@import "partials/guestbook";
@import "partials/calendar";
@import "partials/contact";
@import "partials/footer";
@import "partials/staticElements";
@import "partials/objects-right";
@import "partials/tiny";
//@import "partials/bigvideo";
//@import "partials/btn-bg-zoom";
@import "partials/cards";
@import "partials/pic-caption";
//@import "partials/spa";
@import "partials/slider";
@import "partials/activities";
@import "partials/clients";
//@import "partials/svg";
//@import "lightslider/lightslider";
@import "lightgallery/main";
