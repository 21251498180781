h1,h2,h3,h4,h5,h6{
  &.chapter{
    color: $link-color;
    text-transform: uppercase;
    letter-spacing: .15em;
    font: {
      weight: 800;
    }
  }
}
h1{
  text-transform: uppercase;
  font-size: 7vw;
  font:{
    size:36px;
    weight: 500;
  }
  //letter-spacing: .2em;
  position: relative;
  color: $brand-color-3;
  padding-bottom: 15px;
  margin-bottom: 20px;
  &::after{
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20px;
    height: 3px;
    background: $brand-color-1;
  }
}


.big-title{
  font-size: 200%;
}

@media (min-width: $screen-sm) {
  h1{
    margin-top: 0;
    //font-size: 26px;
    //margin-bottom: 12px;
  }
  .big-title{
    h2{
      font-size: 48px;
      line-height: 1em;
    }
  }
}