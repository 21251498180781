.pic-container {
  position: relative;
  //width: 45%;
  //float: left;
  margin: 0 0 30px;
}
.pic,.picture {
  padding-top: 80%;
  background-position: center;
  background-size: cover;
  //border-radius: 3px;
  .caption-cont {
    //text-align: right;
  }
  .caption {
    display: none;
    position: relative;
    //transform-origin: 0 50%;
    //transform: rotate(-90deg) translateX(-50%);
    transform: translateX(-50%) rotate(-90deg);
    //border: 4px solid #FFF;
    //border-width: 0 4px 4px;
    box-shadow: 0 0 10px 4px rgba($brand-color-4,.05);
    background: #FFF;
    padding: 15px 30px;
    color: $brand-color-4;
    font: {
      family: $headings-font-family;
      size: 10px;
      weight: 500;
    }
    text-transform: uppercase;
    letter-spacing: .4em;
    //margin: 0 11px;

  }
}

@media screen and (min-width: $screen-sm) {
  .pic,.picture{
    .caption {
      display: inline-block;
    }
  }
}