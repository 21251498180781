//Colors
//$brand-color-1: #006CAA;
$brand-color-1: #a4c136;
$brand-color-2: #d16b28;
$brand-color-3: #3b533a;
$brand-color-4: #26342e;
$brand-color-5: #e6e8dc;
//$brand-color-4: #eaf3e3;
//$brand-color-5: #eaf3e3;
$act-colors : #ed6b1c,#da2f89,#8364a9,#0e81c5,#39b186,#98c21d;
$act-pids : 39,30,38,31,28,37;




.brand-color-1{color: $brand-color-1;}
.brand-color-2{color: $brand-color-2;}
.brand-color-3{color: $brand-color-3;}
.brand-color-4{color: $brand-color-4;}

.brand-bg-1{background-color: $brand-color-1;}
.brand-bg-2{background-color: $brand-color-2;}
.brand-bg-3{background-color: $brand-color-3;}
.brand-bg-4{background-color: $brand-color-4;}



//$brand-color-4: set_hsb($brand-color-3,$s:5%,$b:97%);
//$brand-color-4: change_color($brand-color-3, $lightness:95%);
//$brand-color-4: adjust_color($brand-color-3, $lightness:28%);
//$brand-color-5: adjust_color($brand-color-3, $lightness:20%, $saturation:-25%);
$brand-primary : $brand-color-1;

$bg-1 : #393b2f;

$hr-border : $brand-color-1;

// GRID
$grid-columns : 24;
$body-bg:#f6f7ed;
//$body-bg:#2D2634;
//$body-bg:#edf6f7;

// BREAK POINTS
//$screen-sm : 620px;
//$container-sm: 580px;


// NAVBAR

$navbar-default-color:#Fc0;
$navbar-default-bg:#FFF;
$navbar-default-link-color:$brand-color-1;
$navbar-default-link-hover-color:$brand-color-3;
$navbar-default-link-active-color:$brand-color-2;
$navbar-default-link-active-bg:#FFF;
$navbar-default-link-hover-bg:transparent;

//$navbar-bg : none;
//$navbar-inverse-bg:change_hsb($brand-color-1,$b:75%,$s:100%);
$navbar-inverse-bg:mix($brand-color-4,$brand-color-3,50%);
$navbar-inverse-bg: #486d56;
//$navbar-inverse-link-color : rgba(#FFF,0.7);
$navbar-inverse-link-color : #cad396;
$navbar-inverse-link-hover-color: #FFF;
$navbar-inverse-link-hover-bg:transparent;
$navbar-inverse-link-active-color: #FFF;
$navbar-inverse-link-active-bg: transparent;

$nav-bg: rgba(#eee,.95);
$nav-link: #999;
$nav-text:#999;
$nav-hover:#333;
$nav-active:#000;
//$navbar-inverse-link-active-bg:background: linear-gradient(to bottom, lighten($brand-color-2, 20%) 0%, $brand-color-2 100%);
//$dropdown-bg:$brand-color-2;
$dropdown-bg:darken($brand-color-5,3%);
$dropdown-link-color : $brand-color-3;
$dropdown-link-hover-color : #FFF;
$dropdown-link-hover-bg : $brand-color-3;
$dropdown-link-active-color : #FFF;
$dropdown-link-active-bg : $brand-color-1;
$zindex-dropdown : 1500;




//$padding-large-horizontal : 100px;

//$nav-tabs-active-link-hover-bg : red;

// TYPE
$font-family-monospace : 'Roboto Mono', Courier, monospace;

//$headings-font-family : $font-family-monospace;
$headings-font-family : "Raleway", sans-serif;
$headings-font-family : "Montserrat", sans-serif;
//$headings-font-family : "Catamaran", sans-serif;
//$headings-font-family : "Comfortaa", sans-serif;
//$headings-font-family : "Marvel", sans-serif;
$headings-color : $navbar-inverse-bg;
$headings-line-height : 1.3;
$headings-font-weight : 400;

//$font-family-base : "Raleway", sans-serif;
//$font-family-base : "Open Sans", sans-serif;
$font-family-base : "Raleway", sans-serif;
//$font-family-base : "Montserrat", sans-serif;
$font-family-nav : "Raleway", sans-serif;

$font-size-base: 15px;
$text-color:#333;
$line-height-base : 1.8;
$link-color : #2e9972;
$link-hover-color : $headings-color;
$link-hover-decoration: underline;
$blockquote-border-color:$brand-color-1;
//$link-hover-color : $brand-color-2;
//$line-height-computed : 2;

// FORMS
$input-border : rgba(#000,0);
//$input-color : darken($brand-color-5,20%);
//$input-border : lighten($brand-color-3,5%);
$input-border-focus : #FFF;
$input-bg:mix(#FFF,$body-bg,10%);

//$input-color:#FFF;

$input-group-addon-bg : $brand-color-1;
$input-group-addon-border-color : $brand-color-1;


$label-color:#FFF;


$border-radius-base : 0px;
$border-radius-large : 0px;
$border-radius-small : 0px;

// BUTTONS
.btn{
  //font-family: $headings-font-family;
  //text-transform: uppercase;
  //font-weight: bold;
}
$btn-font-weight:500;
//$btn-default-color : change_hsb($brand-color-3,$b:60%, $s:40%);
//$btn-default-border : change_hsb($brand-color-3,$b:90%, $s:10%);
//$btn-default-bg : change_hsb($brand-color-3,$b:99%,$s:1%);

//$navbar-height:60px;

// PANELS
$panel-default-text : $link-color;
$panel-default-heading-bg : #FFF;
$panel-default-border : mix($brand-color-5,$brand-color-1,50%);


// TOOLTIP
$tooltip-bg:adjust_hsb($brand-color-1,$b:-20%);
//$zindex-modal:1100;
//$tooltip-max-width: 400px;

// TABLES
$table-bg-accent:mix($body-bg,$brand-color-5,50%);
$table-border-color: darken($brand-color-5,5%);

$thumbnail-bg:#FFF;

// PAGINATION
$pagination-color:$brand-color-3;
$pagination-bg: transparent;
$pagination-hover-bg:transparent;
$pagination-border:transparent;
$pagination-hover-color:$brand-color-3;
$pagination-hover-border:$brand-color-1;
$pagination-active-color:$brand-color-3;
$pagination-active-bg:transparent;
$pagination-active-border:$brand-color-3;
$pagination-disabled-color:rgba($brand-color-3,.5);
$pagination-disabled-border:rgba(#000,0);
$pagination-disabled-bg:transparent;

// BREADCRUMB
$breadcrumb-bg:transparent;
$breadcrumb-padding-vertical:0;
$breadcrumb-padding-horizontal:0;

$alert-padding: 30px;