.gallery {
  a[rel="tinybox|gallery"] {
    perspective: 1000px;
    &::after {
      content: '\f00e';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      color: #FFF;
      text-align: center;
      z-index: 10;
      transform: translate3d(0, -50%, 300px);
      transition: all .3s ease;
      opacity: 0;
      //text-shadow: 0 0 10px #000;
      font: {
        family: FontAwesome;
        size: 42px;
        weight: normal;
      }

    }
    &:hover {
      &::after {
        opacity: 1;
        transform: translate3d(0, -50%, 0px);
      }
    }
  }
}