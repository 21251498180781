body.lev_0-47 {
  &.details {
    h1 {
      display: none;
    }
    .breadcrumb {
      margin-bottom: 10px;
      .active {
        display: none;
      }
    }
    .art-item {
      h3 {
        margin-top: 0;
        margin-bottom: 5px;
      }
    }
  }
}
.dl-article, #product-config dl{
  margin-bottom: 0;
}
.art-ul {
  > .col-sm-8 {
    margin-bottom: 20px;
    &:nth-child(3n+1) {
      clear: left;
    }
  }
}
.order-info{
  margin: 60px 0 80px;
  .row{
    margin-bottom: 16px;
  }
}

.price {
  line-height: 1.8;
  display: inline-block;
  white-space: nowrap;
  padding: 0 .8em;
  background: #FFF;
  border: 2px solid $brand-color-1;
  color: $brand-color-1;
  border-radius: 1em;
  font: {
    family: $headings-font-family;
    weight: bold;
  }
  //margin-bottom: 5px;
  &.old {
    text-decoration: line-through;
    opacity: .7;
    font-size: 11px;
    border-width: 1px;
  }
  &.promo {
    background: $brand-success;
    color: #FFF;
    border-color: darken($brand-success, 10%);
  }
  & + .price {
    margin-left: 5px;
  }
}

.new {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.8;
  padding: 0 5px;
  border-radius: 10px;
  text-decoration: none !important;
  background: $brand-color-2;
  border: 2px solid darken($brand-color-2, 6%);
  font-size: 9px;
  text-transform: uppercase;
  font-weight: bold;
  color: #FFF;

}

.art-li.product .pic, .side-pic {
  .new {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.art-li {
  &.product {
    height: 100%;
    position: relative;
    overflow: hidden;
    //border: 1px solid $panel-default-border;
    //background: adjust_hsb($body-bg,$b:-5%);
    background: #FFF;
    margin-bottom: 20px;
    box-shadow: 0 4px 2px -2px rgba(#000, .06);
    transition: all .2s ease;

    .pic {
      position: relative;
      padding-top: 0;
    }
    h4 {
      font-size: 18px;
      font-weight: 600;
    }
    .desc {
      padding: 10px;
      font-size: 13px;
      .abstract {
        * {
          font-family: $font-family-base !important;
          color: $brand-color-3 !important;
        }
      }
      .qty, .options {
        display: none;
      }
      /*.btn-round {
        border-radius: 0;
        padding: 6px 12px;
        font-size: $font-size-base;
        line-height: 27px;
      }*/
    }

    &:hover {
      //border-color: $brand-color-1;
      //transform:translateY(-5px);
      box-shadow: 0 4px 8px #cfd1c0;
    }
  }
  &.blog {
    border-bottom: 1px solid #DDD;
    padding-bottom: 30px;
    .pic {
      display: block;
      text-align: center;
      margin: 0 0 20px;
    }
  }
  &:hover, &:focus {
    text-decoration: none;
  }
}

.alsoBought {
  //padding: 0 10px !important;
  margin-left: -10px;
  margin-right: -10px;
  .slick-track {
    display: flex;
    .slick-slide {
      display: flex;
      height: auto;
      //align-items: center; //optional
      //justify-content: center; //optional
    }
  }
  h5 {
    font-size: 13.5px;
    color: $link-color;
  }
}

.art-cat {
  .desc {
    min-height: 85px;
  }
}

.art-li-h {
  margin: 0 5px 15px !important;
  position: relative;
  //background: mix(#FFF,$brand-color-1,90%);

  .pic {
    //position: absolute !important;
    //top: 0;
    //left: 0;
    //width: 100%;
    //height: 100%;
  }
  .desc {
    margin-bottom: 15px;
  }
}

.alsoBought-li {
  padding: 10px 8px;
  .art-li.product {
    margin: 0;
    border-color: #FFF;
    &:hover {
      border-color: $brand-color-1;

    }
  }
}

.date {
  font-weight: bold;
  margin: -8px 0 5px;
  font-size: 12px;
  &::before {
    content: '\f133';
    font-family: FontAwesome;
    font-size: 14px;
    font-style: normal !important;
    margin-right: 5px;
    font-weight: normal !important;
  }
}

.art-item {
  margin: 0 0 30px;
  .alert-info{
    .label{
      display: inline-block;
      vertical-align: middle;
      padding: 0.3em 0 0.2em;
      margin-right: 5px;
      width: 36px;
      text-align: center;
    }
  }
  .product-desc {
    line-height: 1.6;
    font-size: 14px;
    margin: 0 0 30px;
    overflow: hidden;

  }
  h5.chapter {

    font: {
      size: 11px;
    }
  }
  .side-pic {
    position: relative;
    //border: 1px solid $panel-default-border;
    background: #FFF;
    //padding: 5px;
    //padding: 5px;
    box-shadow: 0 4px 2px -2px rgba(#000, 0.06);
    text-align: center;
    margin-bottom: 20px;
    > img {
      max-width: 50%;
    }
  }
  .info {
    border-radius: 5px;
    text-align: center;
    margin-top: 30px;
    padding: 15px;
    //border-top: 1px solid $panel-default-border;
    background: $brand-color-5;
  }
  form.product {
    margin: 0px 0;
  }
  .panel-default {
    background-color: $brand-color-5;
    border-width: 0;
    box-shadow: none;
    .panel-heading,.panel-body{
      padding:20px 30px;
    }
    .panel-heading{
      background-color: #FFF;
      border-width: 0;
      h4{
        font-size: 14px;
        line-height: 24px;
        .step{
          display: inline-block;
          vertical-align: middle;
          width: 30px;
          height: 30px;
          line-height: 30px;
          margin-right: 10px;
          border-radius: 50%;
          text-align: center;
          background: $link-color;
          color: #FFF;
        }
      }
    }
  }
}

ul.documents {
  list-style: none;
  padding: 0;
  a {
    &::before {
      content: '\f016';
      font-family: FontAwesome;
      font-size: 14px;
      margin-right: 5px;
    }
  }
}

a.LienPDF {
  &::before {
    content: '\f016';
    font-family: FontAwesome;
    font-size: 14px;
    margin-right: 5px;
  }
}

.product-options {
  margin-bottom: 40px;
}

.product-cfg-sheet {
  padding: 10px 15px 20px;
  background: adjust_hsb($brand-color-5, $b: 3%);
  margin-bottom: 50px;
}

#options-table {
  tbody + tbody {
    //border-top:none;
  }
  h4 {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .15em;
    font: {
      size: 14px;
      weight: bold;
    }
    color: $link-color;
    margin-top: 5px;
  }
  h5 {
    margin: 3px 0;
  }
  .price {
    margin-bottom: 0;
  }
  [data-action="remove-option"] {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #FFF;
    line-height: 24px;
    color: $brand-danger;
    box-shadow: 0 0 0 2px rgba(#000, .08);
    &:hover {
      background: $brand-danger;
      color: #FFF;
      //box-shadow: 0 0 0 2px $brand-danger;
    }
  }
  tfoot {
    border-bottom: 2px solid $table-border-color;
    background: $brand-color-5;
  }
}

@media screen and (max-width: $screen-sm-max) {

  .art-item {
    .qty {
      text-align: center;
      max-width: 60px;
      margin: 0 auto 10px;

    }
  }
}

@media screen and (min-width: $screen-sm) {
  .dl-article, #product-config dl{
    margin-bottom: 0;
    &:hover{
      background: rgba(#FFF,.5);
    }
    dt::after,dd::before{
      content: '';
      display: inline-block;
      flex: 1;
      border-bottom: 1px solid rgba(#000,.12);
      margin: 0 -15px 9px 10px;
    }

    dt{
      display: flex;
    }
    dd{
      text-align: right;
      display: flex;
      &::before{
        margin: 0 10px 9px -15px;
      }
    }
  }
  .alsoBought {
    margin-left: 0;
    margin-right: 0;
    padding: 0 30px !important;
    .slick-arrow {
      width: 30px;
      color: $brand-color-1;
      &:hover, &:focus {
        color: $link-color;
      }
      &.slick-prev {
        text-align: left;
      }
      &.slick-next {
        text-align: right;
      }
    }
  }
  .art-item {
    .qty {
      max-width: 80px;
    }
    .side-pic {
      float: right;
      margin-left: 25px;
      max-width: 50%;
      > img {
        max-width: 100%;
      }
    }
  }
  .art-li {
    &.blog {
      .pic {
        max-width: 35%;
        float: right;
        margin: 0 0 10px 30px;
      }
    }
  }
}

@media screen and (min-width: $screen-md) {
  .art-item {
    .slider-products-header {
      .slick-slide {
        .bg-img {
          padding-top: 50%;
        }
      }
    }
    .side-pic {
      //float: right;
      max-width: 50%;
    }
  }
}