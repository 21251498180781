.act-header {
  color: $body-bg;
  margin-bottom: 30px;
  padding: 40px;
  @for $i from 1 through length($act-pids) {
    $col-i: nth($act-colors, $i);
    $pid-i: nth($act-pids, $i);
    &.pid-#{$pid-i} {
      background: linear-gradient(130deg,
              change_hsb($col-i, $s: .7, $b: .5, $a: 1) 10%,
              change_hsb(adjust_hue($col-i, -45deg), $s: .7, $b: .3, $a: 1) 90% //change_hsb(adjust_hue($col-i, 30deg), $s: .7, $b: .3, $a: 1) 90%
      );
      h2 {
        color: $col-i;
      }
    }
  }
  img {
  }
  .logo {
    text-align: center;
  }
  .desc {
    margin-top: 50px;
    h2 {
      font-size: 18px;
      letter-spacing: .15em;
      text-transform: uppercase;
      margin-top: 0;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .act-header {
    padding: 60px 40px;
    display: flex;
    align-items:center;
    .logo, .desc {
      //margin: 0 40px;
    }
    .logo {
      flex: 2;
    }
    .desc {
      margin-top: 0;
      margin-left: 50px;
      flex: 5;
      p:last-child{
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $screen-md) {
  .act-header {
    .logo {
      flex: 1;
    }
    .desc {
      margin-left: 80px;
      flex: 3;
    }
  }
}