.modal-content {
  //padding: 30px;
  border: none;
  > iframe {
    border: none;
    width: 100%;
    min-height: 350px;
  }
}

.modal-header, .modal-body, .modal-footer {
  padding: 0;
}

.modal-header {
  padding: 30px 30px 15px;
  border-bottom: 0 solid #e5e5e5;
  position: relative;
  h4.modal-title {
    font-size: 18px;
    //color: $text-color;
  }
  button.close {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  }
  .fa {
    font-size: 14px;
  }
  &::after {
    content: '';
    clear: both;
    display: block;
    position: absolute;
    bottom: 0;
    left: 30px;
    right: 30px;
    height: 2px;
    background: #e5e5e5;
  }
}

.modal-body {
  padding: 20px 30px;
  position: relative;
}

.modal-footer {
  padding: 15px 30px 30px;
  border-top: 0px solid #e5e5e5;
  position: relative;
  .btn {
    min-width: 100px;
    .fa {
      margin-right: 5px;
    }
  }
  &::before {
    content: '';
    clear: both;
    display: block;
    position: absolute;
    top: 0;
    left: 30px;
    right: 30px;
    height: 1px;
    background: #e5e5e5;
  }
  &:empty {
    padding: 10px;
    &::before {
      display: none;
    }
  }
}

.modal {
  &.fade.fx-fall {
    perspective: 1000px;
    //opacity: 0;
    //transition: opacity .15s linear;
    .modal-dialog {
      transform-origin: 50% 100%;
      transform: translate3D(0, 0, -40px) rotateX(15deg);
      transition: transform .3s ease-out;
    }
    &.in {
      //opacity: 1;
      .modal-dialog {
        transform: translate3D(0, 0, 0) rotateX(0deg);
      }
    }
  }
}

@media screen and(min-width: $screen-sm) {
  .modal {
    &.middle-v {
      text-align: center;
      padding: 0 !important;
      &::before {
        content: '';
        display: inline-block;
        //height: 100%;
        vertical-align: middle;
        margin-right: -4px;
      }
      .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        //margin: 10px 0 100px;
      }
    }
  }
}
