.flip-scroll-container {
  background: #FFF;
  .cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  * html .cf {
    zoom: 1;
  }
  *:first-child + html .cf {
    zoom: 1;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 0;
  }

  th,
  td {
    font-size: $font-size-base;
    margin: 0;
    padding: 8px 15px !important;
    vertical-align: top;
    &:nth-child(odd) {
      background: #f7f7f7;
    }
  }
  th {
    text-align: left;
  }

  table {
    display: block;
    position: relative;
    width: 100%;
  }
  thead {
    font-weight: bold;
    display: block;
    float: left;
  }
  tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  thead tr {
    display: block;
  }
  th {
    display: block;
    text-align: right;
  }
  tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  td {
    display: block;
    min-height: 1.25em;
    text-align: left;
  }

  /* sort out borders */

  th {
    border-bottom: 0;
    border-left: 0;
  }
  td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  tbody tr {
    border-left: 1px solid #babcbf;
  }
  th:last-child,
  td:last-child {
    border-bottom: 1px solid #babcbf;
  }
}

.table {
  &.no-borders {
    td, th {
      border: none;
    }
  }
  tfoot{
    border-top: 2px solid $table-border-color;
  }
}

tr {
  &.group_18 {
    background: #FFF5CB;
  }
}