$home-header: #26342e;
body.pid_012 {
  //background: mix(#FFF, $brand-color-5, 20%);
  //background: #2d2634;
  background: linear-gradient(to bottom, $home-header 250px, #2D2634 750px);
  //color: lighten(#2d2634, 40%);
  color: #b4bba7;
  .news-li {
    color: lighten(#b4bba7, 8%);
    background: #41384a;
    a, a.more {
      color: #FFF;
      &:hover {
        color: #FFF;
        border-bottom-color: #b4bba7;
      }
    }
  }
  header {
    box-shadow: none;
    background: transparent;
  }
  footer {
    .labels {
      background: #41384a;
      .title {
        &::before {
          border-color: transparent #41384a transparent transparent;
        }
        &::after {
          border-color: transparent transparent #41384a transparent;
        }
      }
    }
    background: darken(#2d2634, 3%);
    h4, .copyright {
      border-color: #74786b;
    }
  }
}

.home-services {
  text-align: center;
  font-size: 14px;
  margin: 20px 0 50px;
  .service {
    padding: 20px 30px 30px;
    color: change_hsb($brand-color-2, $s: 30%);
    h4 {
      color: #fff;
      font-weight: 300;
      font-size: 22px;
      .fa {
        display: block;
        font-size: 56px;
        margin-bottom: 8px;
        color: $brand-color-1;
      }
    }
    a {
      display: inline-block;
      color: $brand-color-1;
      border: 2px solid $brand-color-1;
      padding: 2px 16px;
      border-radius: 16px;
      font-weight: 600;
      transition: all .2s ease;
      //text-transform: uppercase;
      //font-size: 10px;
      //line-height: 1.6em;
      &:hover {
        text-decoration: none;
        background: #FFF;
        border-color: #FFF;
        color: $brand-color-1;
      }
    }
  }
}

.home-video {
  //padding: 40px 0;
}

.home-testimonials {
  padding: 20px 0 50px;
}

.home-quotes {
  position: relative;
  float: none;
  margin: 20px auto;
  padding-left: 50px;
  padding-right: 50px;
  //border: 1px solid red;

  &::before, &::after {
    //content: open-quote;
    content: '“';
    position: absolute;
    line-height: 0;
    top: 40px;
    left: -10px;
    font-family: Georgia, Times New Roman, Times, serif;
    z-index: -1;
    font-size: 100px;
    color: $brand-color-2;
  }
  &::after {
    //content: close-quote;
    content: '„';
    top: auto;
    left: auto;
    right: -10px;
    bottom: 40px;
  }
  .slick-dots {
    bottom: -24px;
    line-height: 0;
    li {
      button:before {
        color: #BBB;
      }
      &.slick-active button:before {
        color: $brand-color-2;
        text-shadow: none;
      }
    }
  }
}

.home-quote {
  padding: 0;
  font-size: 21px;
  line-height: 1.4em;
  //margin: 0 50px;
  //text-align: center;
  i, em {
    font-family: Georgia, Times New Roman, Times, serif;
  }
  p:last-child:not(:first-child):not(:empty) {
    font-size: 15px;
    margin-bottom: 0;
    i, em {
      font-family: inherit;
    }
    &::before {
      content: '';
      display: inline-block;
      height: 2px;
      width: 20px;
      margin-right: 10px;
      background: #CCC;
      vertical-align: middle;
    }
  }

}

.home-emailing {
  background: #f3f3f3 url('../images/i_layout/coffie-paper.jpg') center;
  background-size: cover;
  padding: 20px 0 50px;
}

.home-social-feeds {
  padding: 40px 0;
  iframe {
    width: 100% !important;
    //height: auto !important;
    max-height: 300px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .item {
    border: 1px solid #e5e5e5;
    padding: 15px 30px;
    margin-bottom: 20px;
  }
}

.home-press {
  padding: 40px 0;
  font-size: 14px;
  background: #f3f3f3 url('../images/i_layout/newspaper.jpg') center;
  background-size: cover;
  .item {
    box-shadow: 0 2px 0 rgba(#000, .05);
    //border: 1px solid #e5e5e5;
    padding: 15px 30px;
    margin-bottom: 20px;
    background: #FFF;
    //border-radius: 3px;
    .date {
      font-weight: bold;
      letter-spacing: .1em;
      &::before {
        content: '\2015';
        margin-right: 5px;
      }
    }
    .abstract {

    }
  }
}

section {
  &.bg-services {
    background: $brand-color-3 url('../images/i_layout/services.jpg') center;
    background-size: cover;
  }
  > .section-title {
    height: 20px;
    margin: 0 auto;
    text-align: center;
    //line-height: 0;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: .5em;

    span {
      text-align: center;
      transform: translateY(-50%);
      position: relative;
      //line-height: 1em;
      text-transform: uppercase;
      color: #FFF;
      background: darken($brand-color-2, 3%);
      text-shadow: 0 -1px 0 darken($brand-color-2, 10%);
      display: inline-block;
      border-radius: 20px;
      padding: 8px 2.2em 8px 2.7em;
    }
  }
}

.slick-prev {
  left: 10px;
  z-index: 1;
}

.slick-next {
  right: 10px;
  z-index: 1;
}

.slider-caption {
  padding: 40px 0 30px;
  margin-bottom: 50px;
  box-shadow: inset 0 -3px 0 rgba(#000, .3);

  .padH {
    padding: 0 8%;
  }
  //border-radius: 0 0 6px 6px;
  h2 {
    font: {
      weight: 300;
      size: 4em;
    }
    letter-spacing: .15em;
    margin-top: 0;
  }
  h4 {
    text-transform: none;
    font-weight: 600;
    margin-top: 0;
  }
}

.slider-home-cont {
  //margin-top: -30px;
  //background: linear-gradient(to bottom, $home-header 0%, #2D2634 100%);
  //padding-top: 20px;
}

.slider-home {
  margin-bottom: 40px;
  .slick-slide {
    height: 300px;
    background-position: center;
    background-size: cover;
    text-shadow: 0 0 5px rgba(#000, .5), 0 0 30px rgba(#000, 1);
    p, h2, h3, h4, h5, h6 {
      color: #FFF;
    }
    h6 {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.6em;
      margin: 0;
    }
    h2 {
      font-weight: 700;
      letter-spacing: .15em;
      text-transform: uppercase;
      margin: 5px 0;
      text-shadow: 0 0 10px rgba(#000, .5);
    }
    p {
      line-height: 1.4;
      margin: 0 0 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .btn-primary {
      text-shadow: none;
      box-shadow: 0 2px 5px rgba(#000, .5);
      border: none;
      text-transform: uppercase;
      font: {
        weight: bold;
        size: 10px;
      }
      letter-spacing: .2em;
      line-height: 2.6;

      &:hover {
        background-color: $brand-color-2;
      }
    }
  }
}

.home-activities-cont, .home-news {
  h2 {
    text-transform: uppercase;
    color: $brand-color-1;
    color: #FFF;
    //color: nth($act-colors,2);
    letter-spacing: .16em;
    font: {
      size: 36px;
      weight: 300;
    }
  }
}

.home-activities-cont {
  margin-top: 80px;
  //color: change_hsb(nth($act-colors, 3), $b: 80%, $s: 15%);
  color: #b4bba7;

  .bg-list {
    //background: mix(#FFF, $brand-color-5, 20%);
    //background: #2d2634;
    padding: 0 0 140px;
    margin-top: 40px;
    margin-bottom: -80px;
    //box-shadow: 0 140px 0 mix(#FFF, $brand-color-5, 20%) inset;
  }
}

.home-activities {
  margin-bottom: 50px;
  > div {
    @for $i from 1 through length($act-colors) {
      $col-i: nth($act-colors, $i);
      &:nth-child(#{$i}) {
        .bg-grad {
          &::before {
            background: linear-gradient(130deg,
                    change_hsb($col-i, $s: .6, $b: .8, $a: 1) 10%,
                    change_hsb(adjust_hue($col-i, -45deg), $s: .9, $b: .4, $a: 1) 90%
            );
            //box-shadow: 0 0 60px rgba($col-i,1) inset;

          }
        }
        .body {
          //background: $col-i;
          //background: rgba(adjust_hue($col-i, 45deg),.5);

          &::before, &::after {
            background: $col-i;
          }
        }

      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .slider-caption {
    padding: 40px 0 30px;
    &.what {
      min-height: 30vh;
    }
    .padH {
      padding: 0 4%;
    }
  }

}

@media screen and (min-width: $screen-lg) {
  .slider-home {
    .slick-slide {
      height: 500px;
      h2 {
        font-size: 42px;
      }
    }
  }
}
