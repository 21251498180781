.clients{
  margin-top: 50px;
  >div{
    margin-bottom: 30px;
    div{
      text-align: center;
      background: #FFF;
      box-shadow: 0 1px 0 rgba(#000,.05);
      height: 200px;
      line-height: 200px;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .clients{
    //margin:30px 8%;
  }
}