//$footer-bg: change_hsb($brand-color-1,$s:5%,$b:100%);
$footer-bg: $brand-color-4;
$footer-color: mix($brand-color-5, $brand-color-4, 70%);
//$footer-link: lighten($footer-bg,50%);
//$footer-link: change_hsb($brand-color-2, $s: 20%);
$footer-link: $footer-color;
$footer-link-hover: #FFF;
$footer-top-margin : 140px;
footer {
  //color: adjust_hsb($brand-color-3, $b: -40%);
  //color: change_hsb($brand-color-2, $s: 30%);
  color: $footer-color;
  //border-top: 2px solid $brand-color-1;
  //background: $brand-color-3;
  background: $footer-bg;
  //background: linear-gradient(to bottom, mix($footer-bg,#FFF, 15%) 0%, $footer-bg 100%);
  font-weight: 500;
  //box-shadow: 0 2px 2px rgba(#000,.1) inset;
  //display: none;

  padding: 0 0 70px;
  //$footer-bg: darken($brand-color-2, 2%);
  margin: $footer-top-margin 0 0;
  font-size: 14px;

  //background: $footer-bg;
  //color: #999;
  //color: $footer-links;
  > div {
    //padding-top: 30px;
  }
  .labels {
    margin: -50px 0 70px;
    background: #425b50;
    text-transform: uppercase;
    letter-spacing: .3em;
    color: #FFF;
    border-radius: 3px;
    overflow: hidden;
    font: {
      family: $headings-font-family;
      size: 10px;
      weight: 400;
    }
    > div {
      line-height: 100px;
      text-align: center;
      .title {
        position: relative;
        background: rgba(#a7b88c, .5);
      }
    }
  }
  h4 {
    color: #FFF;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: .18em;
    font-weight: 400;
    font-size: 14px;
    //border-bottom: 2px solid rgba($footer-links,.2);
    border-bottom: 2px solid #425b50;
    //border-bottom: 2px solid $brand-color-4;
    //border-bottom: 2px solid lighten($footer-bg,8%);
    //border-bottom: 2px solid #CCC;
    padding-bottom: 14px;

    .fa {
      font-size: 14px;
    }
    a {
      padding: 14px 0;
      margin: -14px 0;

      color: #FFF;
      display: block;
      transition: all .2s ease;
      &:hover {
        background: $brand-color-1;
        background: rgba(#000, .2);
        color: #FFF;
        padding-left: 10px;

        text-decoration: none;
      }
    }
  }
  nav {
    .fa, .el {
      display: none;
    }
  }
  nav.activities {
    ul {
      padding: 0;
      list-style: none;
    }
    li {
      > a {
        position: relative;
        padding-left: 14px;
        display: inline-block;
        &::before {
          content: '';
          position: absolute;
          top: 8px;
          left: 0;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #FFF;
        }
        &:hover {
          &::before {
            background: #FFF;
          }
        }
      }
      @for $i from 1 through length($act-pids) {
        $col-i: nth($act-colors, $i);
        $pid-i: nth($act-pids, $i);
        &.pid-#{$pid-i} {
          > a {
            &::before {
              transition: all .2s ease;
              background: $col-i;
            }
            &:hover {
              &::before {
                box-shadow: 0 0 0 1px $col-i;
              }
            }
          }
        }
      }
    }
  }
  hr {
    border-color: $footer-color;
    margin: 0 0 5px;
  }
  .fa:not(.fa-at) {
    margin-right: 5px;
  }
  a {
    color: $footer-link;
    &:hover, &:focus, &:active {
      color: $footer-link-hover;
      text-decoration: none;
      //color: #FFF;
    }
  }
  //box-shadow: 0 3px 3px #eaeaea inset;
  //border-top: 3px solid #dde7f0;
  .copyright {
    margin-top: 30px;
    padding-top: 15px;
    border-top: 2px solid #425b50;
    font-size: 13px;
    //color: lighten($footer-bg, 20%);
    a {
      //color: lighten($footer-bg, 30%);
      &:hover {

      }
    }
  }
  .social-links {
    position: relative;
    padding: 10px 0;
    margin: 15px 0;
    list-style: none;
    border: 1px solid mix($footer-color, $footer-bg, 30%);
    border-width: 1px 0;
    //text-align: center;
    li {
      display: inline;
      & + li {
        margin-left: 3px;
      }
      a {
        //border: 2px solid #999;
        color: $brand-color-3;
        background: $footer-color;
        .fa {
          font-size: 18px;
          margin: 0;
          line-height: 1.3;
        }
      }
      &.title {
        //position: absolute;
        //display: block;
        //text-align:left;
        //background: $footer-bg;
        //width: 100%;
        //top: -12px;
        //left: 0;
        //transform: translate3D(0,-10px,0);
        span {
          font-size: 10px;
          text-transform: uppercase;
          //padding: 0 5px;
        }
      }
    }
    .btn {
      transition: all .3s ease;
      &:hover {
        &.btn-facebook {
          background: #3B5998;
          border-color: #3B5998;
          color: #FFF;
        }
        &.btn-twitter {
          background: #1DA1F2;
          border-color: #1DA1F2;
          color: #FFF;
        }
        &.btn-linkedin {
          background: #0077B5;
          border-color: #0077B5;
          color: #FFF;
        }
      }
    }
  }
}
.footer-flush{
  margin-bottom:-$footer-top-margin ;
  padding-bottom: $footer-top-margin;
}

.footer-nav {
  .col-sm-4 {
    margin-bottom: 15px;
    &:nth-child(3n+1) {
      clear: left;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding-left: 10px;
    }
    a {
      //color: #999;
      color: change_hsb($brand-color-2, $s: 20%);

      position: relative;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
      &::before {
        content: '\f0da';
        position: absolute;
        top: 1px;
        left: -9px;
        display: block;
        font-family: FontAwesome;
        font-size: 10px;
      }
    }
    &.v-trunc {
      position: relative;
      //margin-bottom: 5px;
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        height: 60px;
        background: linear-gradient(to bottom, rgba($panel-footer-bg, 0) 0%, $footer-bg 100%);
        pointer-events: none;
        transition: height 1s ease;
      }
      &.v-on {
        &::after {
          height: 0;
        }
      }
    }
    & + .toggler {
      font-size: 10px;
      font-weight: 900;
      text-transform: uppercase;
      display: inline-block;
      cursor: pointer;
      color: $brand-color-1;
      &:hover {
        color: #fff;
      }
      .plus, .minus {
        letter-spacing: .2em;
        &::before {
          content: '\f0d7';
          font-family: FontAwesome;
          font-weight: normal;
          margin-right: 5px;
        }
      }
      .minus {
        &::before {
          content: '\f0d8';
        }
      }

    }
  }
}

@media (min-width: $screen-sm) {
  footer {
    .labels {
      padding-right: 30px;
      > div {
        .title {
          position: relative;
          background: rgba(#a7b88c, .5);
          &::before, &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 50px 0;
            border-color: transparent #425b50 transparent transparent;
          }
          &::after {
            top: auto;
            bottom: 0;
            border-width: 0 0 50px 20px;
            border-color: transparent transparent #425b50 transparent;
          }
        }
      }
    }
  }
}