.menu-catalog{
  margin: 0 0 30px;
  &.affix{
    position: static !important;
    top:auto;
  }
}
.side-menu-toggler {
  margin-top: 0;
  font: {
    //weight: 700;
    size:16px;
  }
  letter-spacing: .15em;
  text-transform: uppercase;
  padding: 10px 10px;
  //background: change_hsb($brand-color-1, $b: 95%, $s: 8%);
  background: $brand-color-5;
  cursor: pointer;
  border-radius: 3px;
  > .icon {
    display: inline-block;
    font: {
      family: FontAwesome;
      style: normal;
      weight: normal;
    }
    float: right;
    margin-right: 8px;
    &::after {
      content: '\f107';
    }
  }
  &.on {
    > .icon {
      &::after {
        content: '\f106';
      }
    }
  }
}

.side-menu, .cat-prods {
  display: none;
  //font-family: 'Roboto Condensed',sans-serif;
  font: {
    size: 13px;
    weight: 500;
  }
  padding: 0;
  margin: 0;
  //border-bottom: 15px solid #FFF;
  li {
    list-style: none;
    position: relative;
    a {
      color: $navbar-inverse-bg;
      display: block;
      padding: 10px 10px;
      &:hover, &:focus {
        //background: adjust_hsb($brand-color-4,$b:-2%,$s:6%,$a:0.3);
        background: change_hsb($brand-color-3, $b: 96%, $s: 11%, $a: 0.3);
        //color:adjust_hsb($brand-color-3,$b:-20%,$s:20%);
        //color: change_hsb($brand-color-3, $b: 69%, $s: 67%);
        text-decoration: none;
      }
      &.dropdown-toggle {
        .dropdown-icon {
          &::before {
            margin: 0;
          }
        }
        &:hover, &:focus {
          color: $brand-color-3;
        }
      }
    }
    &.active {
      > a {
        //background: change_hsb($brand-color-1, $b: 90%, $s: 30%, $a: 0.2);
        background: $brand-color-5;
        //font-weight: bold;
        //color: #FFF;
        &.dropdown-toggle {
          background: none;
        }
      }
    }
    &.dropdown {
      > a:first-child {
        padding-right: 24px;
      }
    }
  }
  > li {
    border-bottom: 1px solid rgba($navbar-inverse-bg, .2);
  }
  ul {
    border-top: 1px solid rgba($navbar-inverse-bg, .2);
    padding: 0;
    //background: rgba(#f5faff, 0.25);
    //background: adjust_hsb($brand-color-4, $b: 3%);
  }
  li li {
    a {
      padding-left: 20px;

    }
    &:first-child a {
      box-shadow: 0 5px 4px -4px change_hsb($brand-color-4, $s: 15%, $b: 90%, $a: 0.3) inset;
    }
    &:last-child a {
      box-shadow: 0 -5px 4px -4px change_hsb($brand-color-4, $s: 15%, $b: 90%, $a: 0.3) inset;
    }
  }
  .submenu {
    display: none;
  }
  .on > .submenu {
    display: block;
  }
  .dropdown-toggle {
    color: $brand-color-3;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
  }
  .dropdown-icon {
    display: inline-block;
    font-family: FontAwesome;
    font-size: 12px;
    font-weight: normal;
    font-style: normal !important;
    line-height: 0;
    transform: translate(0, 0);
    &::before {
      //vertical-align: middle;
      content: '\f107';

    }
  }
  .on > .dropdown-toggle > .dropdown-icon {
    &::before {
      content: '\f106';
    }
  }
}

.touch .side-menu {
  li {
    a {
      padding: 10px 10px;
      &.dropdown-toggle {
        padding-left: 14px;
        padding-right: 14px;
      }
    }
    &.dropdown {
      > a:first-child {
        padding-right: 36px;
      }
    }
  }
}

.cat-prods {
  margin: 0;
  display: block;
  border-top: 15px solid #FFF;
  h4 {
    font-size: 15px;
    color: #FFF;
    margin: 0 0 10px;
    text-shadow: 0 -1px 0 adjust_hsb($brand-color-1, $s: 15%, $b: -15%);
    background: adjust_hsb($brand-color-1, $s: 8%, $b: -4%);
    padding: 6px 15px;
    border-bottom: 2px solid adjust_hsb($brand-color-1, $s: 15%, $b: -15%);

  }
  ul {
    border: none;
    background: none;
    a {
      padding: 6px 10px 6px 15px;
    }
  }
}

@media (max-width: $screen-xs-max) {

}
@media (min-width: $screen-sm) {

}
@media (min-width: $screen-md) {
  .menu-catalog{
    &.affix{
      position: fixed !important;
      top:80px;
    }
  }
  .side-menu-toggler {
    margin-top: 10px;
    padding: 0;
    background: none;
    cursor: auto;
    > .icon {
      display: none;
    }
  }
  .side-menu {
    border-top: 1px solid rgba($navbar-inverse-bg, .2);
    display: block !important;
    height: auto !important;
    line-height: 1.4;
    li {
      &.active {
        //background-color: #FFF;
        > a {
          background-color: transparent;
          &:first-child {
            position: relative;
            &::before {
              content: '\f0da';
              font-family: FontAwesome;
              display: block;
              position: absolute;
              top: 19px;
              line-height: 0;
              //margin-top: -1px;
              left: -6px;
              //width: 6px;
              //height: 6px;
              //background: $brand-color-2;
              color: $brand-color-2;
              //border-radius: 50%;
            }
          }
          &:hover, &:focus {
            //background: adjust_hsb($brand-color-4,$b:-2%,$s:6%,$a:0.3);
            background: change_hsb($brand-color-3, $b: 96%, $s: 11%, $a: 0.3);
            //color:adjust_hsb($brand-color-3,$b:-20%,$s:20%);
            //color: change_hsb($brand-color-3, $b: 69%, $s: 67%);
            text-decoration: none;
          }
        }
      }

      @for $i from 1 through length($act-pids) {
        $col-i: nth($act-colors, $i);
        $pid-i: nth($act-pids, $i);
        &.pid-#{$pid-i} {
          a:hover {
            background: rgba($col-i, .1);
          }
          &.active {
            a:first-child {
              &::before {
                color: $col-i;
              }
            }
          }
        }
      }
    }
  }
}
