.news-ul{
  margin-top: 50px;
  margin-bottom: 50px;
  //padding-top: 20px;
  //border-top: 1px solid mix($brand-color-3,$body-bg,15%);
  >div{
    margin-bottom: 30px;
  }
}


.news-item {
  margin-top: 30px;
  .body {
    text-align: justify;
    margin-bottom: 30px;
  }
  .picture{
    padding-top: 0;
    margin-bottom: 30px;
  }
  .news-header {
    text-transform: uppercase;
    font: {
      family: $headings-font-family;
      size: 10px;
      weight: 700;
    }
    margin-bottom: 30px;
    letter-spacing: .2em;
    .fa{
      font-size: 12px;
    }
  }

}

div.documents {
  margin: 60px 0 30px;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    width: 30px;
    height: 3px;
    background: $brand-color-2;
  }
  h6 {
    margin-top: 30px;
    font-weight: bold;
  }
}

ul.documents, .documents ul {
  list-style: none;
  padding-left: 0;

  > li {
    > a {
      text-decoration: none !important;
      > span {
        border-bottom: 1px solid rgba(mix(#FFF, $brand-color-1, 70%), 0);
        transition: all .2s ease;
      }
      &::before {
        content: '\f15b';
        display: inline-block;
        vertical-align: middle;
        color: mix(#FFF, $brand-color-1, 70%);
        transform: translateY(-2px);
        transition: color .2s ease;
        font: {
          family: FontAwesome;
          size: 14px;
          weight: normal !important;
          style: normal !important;
        }
        margin-right: 8px;
        text-decoration: none !important;

      }
      &:hover {
        &::before {
          color: $brand-color-2;
        }
        > span {
          border-bottom: 1px solid mix(#FFF, $brand-color-1, 70%);
        }
      }
    }
  }
}


@media screen and (min-width: $screen-sm) {
  .news-li {
    height: 100%;
  }
  .news-item {

  }
}
@media screen and (min-width: $screen-md) {
  .news-item {
    margin-top: 60px;
    .body {
      margin-left: 15%;
      margin-right: 15%;
    }
    .picture{
      max-width: 35%;
      float: left;
      margin-right: 4%;
      margin-bottom: 3%;

    }
  }
}
