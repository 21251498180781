form.product.form-inline {
  .form-group, .form-group-sm {
    display: block;
    margin-bottom: 16px;
  }
  select {
    width: 100%;
    max-width: 100%;
  }
  .options {
    text-align: left;
    margin-bottom: 16px;
    font-size: 13px;
    border-bottom: 1px dotted rgba($brand-color-3, .3);

    .radio {
      display: block;
      border-top: 1px dotted rgba($brand-color-3, .3);

      label {
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
      }
      input[type=radio] {
        & + span {
          &::before {
            background: #FFF;
          }
        }
      }
      & + .radio {

      }
    }
  }
}

textarea.limited.form-control {
  border-radius: $input-border-radius $input-border-radius 0 0;
  position: relative;
  &:focus {
    z-index: 2;
  }
}

label.control-label.required:after {
  font-family: Arial, Helvetica, sans-serif;
}

.textareaStatus {
  font-size: 12px;
  color: #999;
  text-shadow: 0 1px 0 #FFF;
  background: $input-group-addon-bg;
  padding: 2px 10px;
  border-radius: 0 0 $input-border-radius $input-border-radius;
  border: 1px solid $input-border;
  border-top: none;
  z-index: -1;
  .charCounter {
    color: #888;
    font-weight: bold;
  }
}

.text-left {
  text-align: left !important;
}

.input-group-addon {
  //color: change_hsb($brand-color-3, $s: 30%, $b: 70%);
  color: #FFF;
}

.form-control {
  box-shadow: none;
  &:focus {
    box-shadow: none;
    background: #FFF;
  }
}

.form_buttons {
  text-align: center;
  margin: 40px 0;
}

.has-error label.control-label.required {
  &::after {
    content: '\f071';
    font-family: FontAwesome;
    margin-left: 5px;
  }
}

.form-captcha {
  .input-group-addon {
    background-color: $brand-color-3;
  }
  img {
    border-right: 1px solid $brand-color-3;
  }
}

.form-checkbox, .form-radio {
  $check-color: $link-color;
  $check-border: $brand-color-2;
  .radio, .checkbox {
    label {
      padding-left: 22px;
      input[type=radio], input[type=checkbox] {
        //display: none;
        visibility: hidden;
        position: absolute;
        pointer-events: none;
        & + span {
          position: relative;
          &::before {
            content: '\f00c';
            font-family: FontAwesome;
            color: rgba($check-color, 0);
            position: absolute;
            overflow: visible;
            top: 2px;
            left: -22px;
            display: inline-block;
            width: 14px;
            height: 14px;
            background: rgba(#000, .05);
            box-shadow: 0 0 0 2px rgba(#000, .15) inset;
            font-size: 18px;
            line-height: 10px;
            text-indent: -1px;
            transition: color .2s ease;
          }
        }
        &:checked {
          & + span {
            &::before {
              //content: '\f00c';
              //font-family: FontAwesome;
              color: rgba($check-color, 1);

            }
          }
        }
      }
      input[type=radio] {
        & + span {
          &::before {
            content: '';
            border-radius: 50%;
          }
          &::after {
            content: '';
            position: absolute;
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: rgba($check-color, 0);
            top: 6px;
            left: -18px;
            transition: background .2s ease;

          }
        }
        &:checked {
          & + span {
            &::after {
              background: rgba($check-color, 1);
            }
          }
        }
      }
      &:hover {
        input[type=radio], input[type=checkbox] {
          & + span {
            &::before {
              box-shadow: 0 0 0 2px rgba(#000, .25) inset;
            }
          }
        }
      }
      &:active {
        input[type=radio], input[type=checkbox] {
          & + span {
            &::before {
              box-shadow: 0 0 0 2px $check-border inset;
            }
          }
        }
      }
    }
  }
}

label.checkable-btn {
  position: relative;
  display: block;
  //margin: 15px 0;
  .cbtn {
    display: block;
    background: $brand-color-5;
    padding: 10px;
    cursor: pointer;

    transition: all .2s ease;
    .illu {
      text-align: center;
    }
    .title {
      margin: 5px 0 0;
      /*text-align: center;
      font: {
        size: 13px;
        weight: 500;
      }*/
    }
    &:hover {

      //box-shadow: 0 0 0 2px $brand-color-1 inset;
      box-shadow: 0 0 0 2px darken($brand-color-5, 10%) inset;
      .sticker-left {
        background: adjust_hsb($brand-color-5, $b: -25%, $s: 3%);;
      }
    }
    &:active {

      //box-shadow: 0 0 0 2px $brand-color-2 inset;
      box-shadow: 0 0 0 2px darken($brand-color-5, 20%) inset;
    }
    &::after {
      content: '\f00c';
      font: {
        size: 14px;
        family: FontAwesome;
        weight: normal;
        style: normal;
      }
      display: block;
      position: absolute;
      right: 5px;
      top: 5px;
      color: #FFF;
      text-align: center;
      line-height: 26px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: $brand-success;
      opacity: 0;
      transform: scale(0);
      transition: all .2s ease;
    }
    &.cbtn-inline {
      text-align: center;
      .indented {
        text-indent: 100px;
      }
    }
    //border-radius: 3px;
  }
  input[type=radio], input[type=checkbox] {
    visibility: hidden;
    position: absolute;
    &:checked {
      & + .cbtn {
        background: #FFF;
        color: $link-color;
        box-shadow: 0 0 0 2px $link-color inset;
        .sticker-left {
          background: $link-color;
        }
        &:active {
          box-shadow: 0 0 0 2px $brand-color-2 inset;
        }
        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
    &:disabled {
      & + .cbtn {
        cursor: not-allowed;
        opacity: .5;
        &:hover, &:active {
          box-shadow: none;
        }
      }
    }
  }
  .sticker-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    min-width: 100px;
    //background: $link-color;
    background: adjust_hsb($brand-color-5, $b: -15%, $s: 3%);
    color: #FFF;
    line-height: 47px;
    transition: all .2s ease;
  }
}

@media screen and (min-width: $screen-sm) {
  label.checkable-btn {
    height: 100%;
    .cbtn {
      text-align: center;
      height: 100%;
      .title {
        font: {
          size: 13px;
          weight: 600;
        }
      }
    }
  }
}