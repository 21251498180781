.searchBox {
  margin: 0;
  padding: 10px 15px;
  form {
    position: relative;
  }
  input[type=text] {
    padding-right: 40px;
  }
  .btn-default {
    position: absolute;
    top: 1px;
    right: 0;
    border: none;
    background-color: transparent;
    color: $brand-color-1;
    &:hover, &:focus {
      background-color: transparent;
      color: $brand-color-2;
      box-shadow: none;
    }
  }
}

.SearchResults {

}

.SearchResult {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #e5e5e5;
  .path {
    font-size: 18px;
    //color: #FFF;
    list-style: none;
    padding: 0;
    margin: 0 0 5px;
    > li {
      display: inline;
      &::after {
        content: ' / ';
      }
      &:last-child::after {
        content: '';
      }
    }
  }
  .abstract {
    margin: 0 0 10px;
  }
}

@media (min-width: $screen-sm) {
  .searchBox {
    //z-index: 1001;
    padding: 10px 0;
  }
}

@media (min-width: $screen-md) {
  .searchBox {
    position: absolute;
    right: 8px;
    bottom: 8px;
    font-weight: normal;
    font-size: $font-size-base;
    margin: 0;
    padding: 0;
    //z-index: 1001;
    input[type=text].form-control {
      width: 167px;
      background: darken($navbar-inverse-bg,6%);
      color: #FFF;
      box-shadow: none !important;
      border-width: 0;
      border-radius: 18px;
      height: 36px;
      padding: 3px 40px 3px 20px;

      font-family: $font-family-base;

      //background-color: adjust_hsb($brand-color-1, $b: -12%);
      //border: none;
      //color: #FFF;
      transition: background-color 0.3s;
      @include placeholder(lighten($navbar-inverse-bg,20%));

      &:focus {
        background: darken($navbar-inverse-bg,8%);

        //background-color: adjust_hsb($brand-color-1, $b: -20%);
        //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2), 0 0 15px rgba(0, 0, 0, 0);
      }
    }
    .btn-default {
      padding: 3px 12px;
      box-shadow: none;
      //color:adjust_hsb($brand-color-1,$b:5%);
      //color: $brand-color-2;
      transition: color 0.3s;
      &:hover, &:focus {
        background-color: transparent;
        color: #FFF;
        box-shadow: none;
      }
    }
  }

}

@media (min-width: $screen-lg) {
  .searchBox {
    input[type=text].form-control {
      width: 208px;
    }
  }
}